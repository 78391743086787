import {initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider, FacebookAuthProvider, signInWithRedirect, getRedirectResult, linkWithCredential} from "firebase/auth";

export const useFirebase = () => {
  // See: https://firebase.google.com/docs/web/learn-more#config-object
  const firebaseConfig = {
    apiKey: "AIzaSyDmCH-z2hxz5o0L4H8LpgubweimMDqus6A",
    // 2 将本服务域名配置进firebase config
    authDomain: import.meta.env.VITE_DOMAIN_WEBSITE,
    // The value of `databaseURL` depends on the location of the database
    // databaseURL: "https://DATABASE_NAME.firebaseio.com",
    projectId: "bnto-prod-8b92e",
    storageBucket: "bnto-prod-8b92e.firebasestorage.app",
    messagingSenderId: "819752321416",
    appId: "1:819752321416:web:474066936982fc2f007a3d",
    // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
    measurementId: "G-5T84V665WJ",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  console.log("app", app)

  // Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(app);
  console.log("auth", auth)

  /** 谷歌登录 **/
  const googleLoginPopup = () => {
    return new Promise((resolve, reject) => {
      const provider = new GoogleAuthProvider()
      console.log("provider", provider)

      signInWithPopup(auth, provider).then(result => {
        console.log("signInWithPopup result", result)
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        console.log("credential", credential)
        console.log("user", user)
        resolve({platform: "google", idToken: result._tokenResponse.idToken})
      }).catch((error) => {
        console.log("signInWithPopup error", error)
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        reject({error})
      });
    })
  }
  const googleLoginRedirect = async () => {
    const provider = new GoogleAuthProvider()
    console.log("provider", provider)
    await signInWithRedirect(auth, provider)
  }
  const googleLoginRedirectResult = () => {
    return new Promise((resolve, reject) => {
      getRedirectResult(auth).then(result => {
        resolve(result)
      }).catch(e => handleGetRedirectResultError(e, GoogleAuthProvider).then(resolve).catch(reject))
    })
  }
  /** 苹果登录 **/
  const appleLoginPopup = () => {
    return new Promise((resolve, reject) => {
      const provider = new OAuthProvider('apple.com');
      // provider.addScope('email');
      // provider.addScope('name');
      console.log("provider", provider)

      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);

          // IdP data available using getAdditionalUserInfo(result)
          // ...
          console.log("credential", credential)
          console.log("user", user)
          resolve({platform: "apple", idToken: result._tokenResponse.idToken})
        })
        .catch((error) => {
          console.log("signInWithPopup error", error)
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
          reject({error})
        });
    })
  }
  const appleLoginRedirect = async () => {
    const provider = new OAuthProvider('apple.com');
    console.log("provider", provider)
    await signInWithRedirect(auth, provider)
  }
  const appleLoginRedirectResult = () => {
    return new Promise((resolve, reject) => {
      getRedirectResult(auth).then(result => {
        resolve(result)
        /** OAuthProvider 不提供 OAuthProvider.credential()，故传递 null **/
      }).catch(e => handleGetRedirectResultError(e, null).then(resolve).catch(reject))
    })
  }
  /** 脸书登录 **/
  const facebookLoginPopup = () => {
    return new Promise((resolve, reject) => {
      const provider = new FacebookAuthProvider();
      provider.setCustomParameters({
        'display': 'popup'
      });
      console.log("provider", provider)

      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);

          // IdP data available using getAdditionalUserInfo(result)
          // ...
          console.log("credential", credential)
          console.log("user", user)
          resolve({platform: "facebook", idToken: result._tokenResponse.idToken})
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);

          // ...
          reject({error})
        });
    })
  }
  const facebookLoginRedirect = async () => {
    const provider = new FacebookAuthProvider();
    console.log("provider", provider)
    await signInWithRedirect(auth, provider)
  }
  const facebookLoginRedirectResult = () => {
    return new Promise((resolve, reject) => {
      getRedirectResult(auth).then(result => {
        resolve(result)
      }).catch(e => handleGetRedirectResultError(e, FacebookAuthProvider).then(resolve).catch(reject))
    })
  }

  const handleGetRedirectResultError = (e, provider) => {
    return new Promise((resolve, reject) => {
      if (!provider) reject(new Error("Provider is necessary"))
      const BNTOMessage = useNuxtApp().$BNTOMessage
      console.table({...e})
      // todo 暂存
      // if (e.code === "auth/account-exists-with-different-credential") {
      //   resolve(handleAuthAccountExistsWithDifferentCredential(e, provider))
      // } else {
      //   BNTOMessage.error({message: `${e.name}: ${e.code}`});
      //   reject(e)
      // }
      BNTOMessage.error({message: `${e.name}: ${e.code}`});
      reject(e)
    })
  }

  // todo 暂存
  const handleAuthAccountExistsWithDifferentCredential = async (e, provider) => {
    const oauthAccessToken = e.customData._tokenResponse.oauthAccessToken
    const user = e.user
    // 获取 credential from oauthAccessToken
    const credential = provider.credential(oauthAccessToken);
    // 将 三方平台账户 与现有的 Firebase 账户关联
    const userCredential =  await linkWithCredential(user, credential);
    // 用户现在已与其 Facebook 账户关联
    const idToken = userCredential.user.getIdToken(); // 获取 idToken
    console.log("idToken", idToken)
    return idToken
  }

  return {
    googleLoginPopup,
    googleLoginRedirect,
    googleLoginRedirectResult,
    appleLoginPopup,
    appleLoginRedirect,
    appleLoginRedirectResult,
    facebookLoginPopup,
    facebookLoginRedirect,
    facebookLoginRedirectResult
  }
}
